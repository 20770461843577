<template>
	<div class="box">
		<div id="top-box">
			<img src="../../assets/holt.jpg" alt="">
		</div>
		<div id="centre-box">
			<div id="nameBox">
				<div id="nameBox1">
					<p class="name1">{{nameBox.name}}</p>
					<div class="scoreInfo">
						<van-rate v-model="nameBox.score" allow-half void-icon="star" void-color="#eee" size="14"
							color="#FC6228" readonly />
						<p class="score">{{nameBox.score}}分</p>

					</div>
					<p class="info">
						{{nameBox.info}}
						<span>实用信息</span>
					</p>
					<p class="address">
						{{nameBox.address}}
						<van-icon name="location" />
					</p>
				</div>
			</div>
			<div id="conBox">
				<div class="roomList">
					<div class="roomTitle">
						<p v-for="(item,index) in room.title">{{item.name}}</p>
					</div>
				</div>
				<!--  -->
				<div v-for="(item,index) in room.list" class="room-List">
					<div style="font-size: 0.2rem;" class="infoTitle">
						<div class="infoTitle1">
							<p style="font-size: 0.23rem;">{{item.name}}</p>
							<p
								style="font-size: 0.18rem;color: #318AE4;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
								{{item.details}}
							</p>
							<p style="font-size: 0.18rem;">
								<span style="color: #318AE4;">退款无忧 </span> |
								<span>无需换票</span>
							</p>
							<p style="font-size: 0.18rem;">
								<span>已售1万+ </span> |
								<span style="color: #318AE4;">购买须知 ></span>
							</p>

						</div>
						<div style="font-size: 0.2rem;" class="drawUp">
							<p style="font-size: 0.23rem;">
								<span style="text-decoration:line-through;color: #666666;font-size: 11px;">￥160</span>
								<span style="color: #FC6228;">￥{{item.price}}</span>
							</p>
							<button class="onlineF" @click="reserve">预定</button>
						</div>
					</div>
				</div>
				<!--  -->
				<div class="evaluate">
					<h3>网友评价</h3>
					<div class="grade">
						<div class="leftGrade">
							<p style="font-size: 0.5rem;color: #FC6228;">4.5</p>
							<div style="color: #1A1A1A;font-weight: bold;">
								<p>推荐</p>
								<p>85条评论</p>
							</div>
						</div>
						<div class="rightGrade">
							<div class="list1">
								<p>位置 4.9</p>
								<p style="width: 50px;height: 3px;background: #FC6228;border: 2px solid #FC6228;
border-radius: 3px;"></p>
							</div>
							<div class="list1">
								<p>服务 4.9</p>
								<p style="width: 50px;height: 3px;background: #FC6228;border: 2px solid #FC6228;
							border-radius: 3px;"></p>
							</div>
						</div>
					</div>
					<div class="evaluateList">
						<div class="evaluateTitle">
							<p v-for="(item,index) in evaluate.title">{{item.name}}</p>
						</div>
					</div>
					<div v-for="(item,index) in evaluate.peoList" class="characters">
						<div class="topHeader">
							<div class="topHeader1">
								<img :src="item.portrait" alt="" style="width: 60px;height: 60px;border-radius: 50%;">
								<div class="topHeader11">
									<p>{{item.name}}</p>
									<van-rate v-model="item.star" allow-half color="#FC6228" :size="15" readonly />
								</div>
							</div>
							<div class="topHeader2">{{item.time}}入住</div>
						</div>
						<div class="detail">
							{{item.detail}}
						</div>
						<div class="imageList">
							<img :src="item.portrait" alt="" v-for="(item,index) in item.image">
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	export default {
		data() {
			return {
				nameBox: {
					name: "郑州方特欢乐世界",
					score: 4.5,
					address: "郑州市中牟县郑开大道与人文路交叉口向南",
					info: "今日09:00-16:30,16:00停止入场"
				},
				room: {
					title: [{
							name: "今日出游",
						},
						{
							name: "明日出游",
						},
						{
							name: "指定日期",
						},
					],
					list: [{
							name: "成人票",
							details: "可订今日 预定1小时后可用",
							price: 789,

						},
						{
							name: "成人票",
							details: "可订今日 预定1小时后可用",
							price: 789,

						},
						{
							name: "成人票",
							details: "可订今日 预定1小时后可用",
							price: 789,

						}
					]
				},
				evaluate: {
					title: [{
							name: "干净整洁（15）",
						},
						{
							name: "服务好（15）",
						},
						{
							name: "性价比高（15）",
						},
						{
							name: "位置好（15）",
						}
					],
					peoList: [{
							name: "肚子又圆了",
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail: "不错卫生好，价格便宜。房间宽敞，位置好，厨房大可以做饭，有超大玻璃风景无限好",
							star: 3.5,
							time: "2020-03-12",
							image: [{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								}
							]
						},
						{
							name: "胡巴胡巴",
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail: "不错卫生好，价格便宜。房间宽敞，位置好，厨房大可以做饭，有超大玻璃风景无限好",
							star: 4.5,
							time: "2020-05-12",
							image: [{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								}
							]
						}
					]
				}
			}
		},
		methods: {
			reserve() {
				this.$router.push({
					path: '/h5/secnicOrder',
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.box {
		background-color: #FFFFFF;
	}

	#top-box {
		height: 150px;
		background-size: 100% 120%;
		background-position: center center;
		filter: brightness(0.8);
	}

	#top-box img {
		width: 100%;
		height: 100%;
	}

	#centre-box {
		// padding: 4vw 0 0;
		position: relative;
		display: flex;
		// flex-direction: column;
		/* align-items: center; */
		// background-color: #fff;
	}

	#nameBox {
		width: 100%;
		height: 1.6rem;
		position: absolute;
		top: -0.7rem;
		display: flex;
		justify-content: center;
		font-size: 0.2rem;
	}

	#nameBox1 {
		width: 85%;
		height: 100%;
		background: #FFFFFF;
		box-shadow: 0px 3px 10px 0px rgba(61, 61, 61, 0.24);
		border-radius: 20px;
		padding: 0.18rem;
	}

	.name1 {
		font-size: 0.22rem;
		font-weight: bold;
	}

	.scoreInfo {
		display: flex;
		align-items: center;
		margin: 0.15rem 0;
	}

	.score {}

	.info {
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #1A1A1A;
		display: flex;
		justify-content: space-between;
		span{
			font-size: 11px;
			font-weight: 400;
			color: #666666;
		}
	}

	.address {
		font-size: 11px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #999999;
		margin-top: 0.2rem;
		display: flex;
		justify-content: space-between;
	}

	#conBox {
		width: 100%;
		margin-top: 1.5rem;
		padding: 5px 8px;
	}

	.roomList {
		font-size: 0.2rem;
		margin-bottom: 10px;
	}

	.roomTitle {
		display: flex;
		p {
			background-color: #F4F5F9;
			padding: 3px 5px;
			margin-left: 5px;
		}
	}

	.room-List {
		// margin-top: 10px;
		display: flex;
		align-items: center;
		background: #F4F5F7;
		justify-content: center;
	}

	.infoTitle {
		width: 90%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 8px;
	}

	.infoTitle1 {
		width: 70%;
		p {
			margin: 8px 0;
		}
	}

	.drawUp {
		text-align: center;
	}

	.onlineF {
		width: 66px;
		height: 28px;
		line-height: 28px;
		background: linear-gradient(80deg, #FF7C22 0%, #FF5414 100%);
		border-radius: 14px;
		font-size: 0.18rem;
		color: #FFFFFF;
		margin-top: 10px;
		border: none;
	}

	.evaluate {
		h3 {
			font-size: 0.23rem;
		}

		.grade {
			font-size: 0.2rem;
			display: flex;

			.leftGrade {
				display: flex;
			}

			.rightGrade {
				margin-left: 15px;

				.list1 {
					display: flex;
					align-items: center;
				}
			}
		}

		// 
		.evaluateList {
			font-size: 0.2rem;

			.evaluateTitle {
				// float: left;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 10px 0;

				p {
					background-color: #F4F5F9;
					width: 1.5rem;
					height: 0.4rem;
					line-height: 0.4rem;
					text-align: center;
					margin-bottom: 5px
				}
			}
		}

		// 
		.characters {
			font-size: 0.2rem;

			.topHeader {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.topHeader1 {
					display: flex;
					align-items: center;

					img {}

					.topHeader11 {
						margin-left: 8px;
					}
				}

				.topHeader2 {
					color: #999999;
				}
			}

			.detail {
				color: #333333;
				margin: 10px 0;
			}

			.imageList {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				img {
					width: 110px;
					height: 110px;
					border-radius: 10px;
				}
			}
		}
	}
</style>
